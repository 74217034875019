@tailwind base;
@tailwind components;
@tailwind utilities;

p a {
  @apply underline;
}

body,
#root,
.leaflet-container {
  width: 100vw;
  max-width: 100%;
  margin: 0;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.trip-info-table p {
  @apply mb-2;
  @apply last:mb-0;
}

.trip-info-table td {
  @apply px-2;
  @apply py-2;

  @apply first:min-w-[6em];

  @apply border;
  @apply border-solid;
  @apply border-slate-200;
}

.trip-info-table tr:last-child td {
  @apply pb-0;
}

@keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.8;
  }
}

.gps-loading-position {
  animation: fadein 0.5s linear alternate infinite;
}
